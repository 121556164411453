import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ServiceWorkerService} from "../../core/services/service-worker.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  constructor(
    private serviceWorkerService: ServiceWorkerService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.serviceWorkerService.forceUpdate();
    }
  }

}
