import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./base/home/home.component";
import {AboutUsComponent} from "./base/about-us/about-us.component";
import {ResearchComponent} from "./base/research/research.component";
import {AdapFaqComponent} from "./base/adap/adap-faq/adap-faq.component";
import {AdapComponent} from "./base/adap/adap.component";
import {ProductsComponent} from "./base/products/products.component";
import {FaqComponent} from "./base/products/faq/faq.component";
import {ConsultingComponent} from "./base/consulting/consulting.component";
import {UpdateComponent} from "./base/update/update.component";
import {PublicationsComponent} from "./base/publications/publications.component";
import {ScreenxComponent} from "./base/products/screenx/screenx.component";
import {ScanxComponent} from "./base/products/scanx/scanx.component";
import {SamaeComponent} from "./base/products/samae/samae.component";
import {ProductFaqComponent} from "./base/products/product-faq/product-faq.component";
import {ScanxHowToComponent} from "./base/products/scanx/scanx-how-to/scanx-how-to.component";
import {SamaeExerciseComponent} from "./base/products/samae/samae-exercise/samae-exercise.component";
import {SamaeHowToComponent} from "./base/products/samae/samae-how-to/samae-how-to.component";

const routes: Routes = [
  {path: "", component: HomeComponent},
  {path: "about-us", component: AboutUsComponent},
  {path: "contact-us", component: FaqComponent},
  {path: "faq", component: FaqComponent},
  {path: "consulting", component: ConsultingComponent},
  {path: "publications", component: PublicationsComponent},
  {path: "research-articles", component: ResearchComponent},
  {path: "products", component: ProductsComponent,},
  {path: 'products/screenx', component: ScreenxComponent},
  {path: 'products/scanx', component: ScanxComponent},
  {path: 'products/scanx/how-to', component: ScanxHowToComponent},
  {path: 'products/samae', component: SamaeComponent},
  {path: 'products/samae/how-to', component: SamaeHowToComponent},
  {path: 'products/samae/exercise', component: SamaeExerciseComponent},
  {path: 'faq', component: ProductFaqComponent},
  {
    path: "adap",
    component: AdapComponent,
    children: [
      {path: 'faq', component: AdapFaqComponent},
    ]
  },
  {path: '**', component: UpdateComponent}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
