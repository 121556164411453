import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from "../shared/shared.module";
import {MDBBootstrapModulesPro} from "ng-uikit-pro-standard";
import {HomeComponent} from './home/home.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {ResearchComponent} from './research/research.component';
import {ScreenxComponent} from "./products/screenx/screenx.component";
import {ScanxComponent} from "./products/scanx/scanx.component";
import {SamaeComponent} from "./products/samae/samae.component";
import {ProductFaqComponent} from "./products/product-faq/product-faq.component";
import {AdapFaqComponent} from "./adap/adap-faq/adap-faq.component";
import {AdapComponent} from './adap/adap.component';
import {ProductsComponent} from './products/products.component';
import {AppRoutingModule} from "../app-routing.module";
import {FaqComponent} from './products/faq/faq.component';
import {ConsultingComponent} from './consulting/consulting.component';
import {UpdateComponent} from "./update/update.component";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PublicationsComponent} from './publications/publications.component';
import {SamaeExerciseComponent} from './products/samae/samae-exercise/samae-exercise.component';
import {ScanxHowToComponent} from './products/scanx/scanx-how-to/scanx-how-to.component';
import {SamaeHowToComponent} from './products/samae/samae-how-to/samae-how-to.component';

@NgModule({
  declarations: [HomeComponent,
    AboutUsComponent,
    ContactUsComponent,
    ScreenxComponent,
    ScanxComponent,
    SamaeComponent,
    ProductFaqComponent,
    AdapFaqComponent,
    UpdateComponent,
    ResearchComponent,
    AdapComponent,
    ProductsComponent,
    FaqComponent,
    ConsultingComponent,
    PublicationsComponent,
    SamaeExerciseComponent,
    ScanxHowToComponent,
    SamaeHowToComponent],
  imports: [
    SharedModule,
    MDBBootstrapModulesPro.forRoot(),
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
  ]
})
export class BaseModule {
}
