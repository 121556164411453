import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavComponent} from './nav/nav.component';
import {MDBBootstrapModulesPro} from "ng-uikit-pro-standard";
import {AppRoutingModule} from "../app-routing.module";
import {FooterComponent} from './footer/footer.component';

@NgModule({
  declarations: [NavComponent, FooterComponent],
  exports: [NavComponent, FooterComponent],
  imports: [
    MDBBootstrapModulesPro.forRoot(),
    AppRoutingModule,
    CommonModule
  ]
})
export class SharedModule {
}
