import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ServiceWorkerService} from "./core/services/service-worker.service";
import {isPlatformBrowser} from "@angular/common";
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  routerSubscription: Subscription;

  constructor(
    private router: Router,
    private serviceWorkerService: ServiceWorkerService,
    @Inject(PLATFORM_ID) private platformId: Object) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.serviceWorkerService.checkForUpdate();

      this.routerSubscription = this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd))
        .subscribe(() => {
          document.body.scrollTop = 0;
        });
    }
  }


  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

}
