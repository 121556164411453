import {Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';


@Injectable()
export class ServiceWorkerService {

  constructor(private updates: SwUpdate) {
    if (this.updates.isEnabled) {
      this.updates.available.subscribe(() => {
        window.location.reload();
      });
    }
  }

  public checkForUpdate() {
    if (this.updates.isEnabled) {
      this.updates.checkForUpdate();
    }
  }

  public forceUpdate() {
    if (this.updates.isEnabled) {
      this.updates.checkForUpdate();
      this.updates.available.subscribe(() => {
        console.log('found update');
        this.updates.activateUpdate().then(() => document.location.reload());
      });
      setTimeout(() => {
        console.log('Update timeout');
        window.location.href = '/';
      }, 10000);
    } else {
      window.location.href = '/';
    }
  }
}
