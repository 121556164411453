import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {ContactUsRequest} from "../../model/contact-us-request";
import {ToastService} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  public contactUsForm = this.fb.group({
    email: [null, Validators.compose([Validators.required, Validators.email])],
    name: [null, Validators.required],
    subject: [null, Validators.required],
    message: [null, Validators.required]
  });
  public contactUsSent = false;
  public inProgress = false;


  contactUsUrl = "/rest/contact-us";

  constructor(public fb: FormBuilder,
              private http: HttpClient,
              private toastrService: ToastService) {
  }

  ngOnInit() {
  }

  sendRequest(): void {

    this.contactUsForm.markAsDirty();

    if (this.inProgress || this.contactUsSent || !this.contactUsForm.valid) {
      return;
    }

    let model: ContactUsRequest = this.contactUsForm.value;

    this.inProgress = true;
    this.http.post<Response>(this.contactUsUrl, model)
      .subscribe(response => {
          this.contactUsSent = true;
          this.inProgress = false;
        },
        error => {
          this.inProgress = false;
          this.toastrService.error('Please contact us on sales@leaderware.com',
            'A Technical error has occurred',
            {tapToDismiss: true, timeOut: 60_000, extendedTimeOut: 60_000, positionClass: 'md-toast-bottom-center'});
        }
      );

  }
}
