import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-faq',
  templateUrl: './product-faq.component.html',
  styleUrls: ['./product-faq.component.scss']
})
export class ProductFaqComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
