import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-samae-exercise',
  templateUrl: './samae-exercise.component.html',
  styleUrls: ['./samae-exercise.component.scss']
})
export class SamaeExerciseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
