import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scanx-how-to',
  templateUrl: './scanx-how-to.component.html',
  styleUrls: ['./scanx-how-to.component.scss']
})
export class ScanxHowToComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
