import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scanx',
  templateUrl: './scanx.component.html',
  styleUrls: ['./scanx.component.scss']
})
export class ScanxComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
