import {NgModule, Optional, SkipSelf} from '@angular/core';
import {MDBSpinningPreloader, ToastService} from "ng-uikit-pro-standard";
import {ServiceWorkerService} from "./services/service-worker.service";

@NgModule({
  providers: [MDBSpinningPreloader,
    ToastService,
    ServiceWorkerService]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
