import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-screenx',
  templateUrl: './screenx.component.html',
  styleUrls: ['./screenx.component.scss']
})
export class ScreenxComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
